<template>
  <div class="aboutYouBodyHeader">
    <base-heading
      type="4"
      class="aboutYouBodyHeader__title"
    >
      {{ $t('body.pageTitle') }}
    </base-heading>

    <div class="aboutYouBodyHeader__buttons">
      <base-button
        v-bind:to="{ name: 'profile.connection' }"
        modifiers="secondary sm"
        class="aboutYouBodyHeader__button"
      >
        {{ $t('body.connectionsButtonLabel') }}
      </base-button>

      <base-button
        modifiers="primary sm"
        class="aboutYouBodyHeader__button"
        v-on:click="onNewBodyMeasurementsClick"
      >
        <font-awesome-icon
          v-bind:icon="['fa', 'plus']"
          class="aboutYouBodyHeader__buttonIcon"
        />

        {{ $t('body.measurementsModalButtonLabel') }}
      </base-button>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent, markRaw } from 'vue';
import BaseButton from '@/components/BaseButton';
import BaseHeading from '@/components/BaseHeading';
import { ModalBus } from '@/eventBus';

const BodyMeasurementsModal = markRaw(defineAsyncComponent({
  loader: () => import('@/components/aboutyou/body/BodyMeasurementsModal' /* webpackChunkName: "bodyMeasurementsModal" */),
}));

export default {
  components: {
    BaseButton,
    BaseHeading,
  },

  methods: {
    onNewBodyMeasurementsClick() {
      ModalBus.emit('open', {
        component: BodyMeasurementsModal,
        closeCallback: (trigger) => {
          if (trigger === 'component') {
            this.$emit('UPDATED_BODY_MEASUREMENTS');
          }
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.aboutYouBodyHeader {
  @include desktop {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.aboutYouBodyHeader__title {
  margin: 0 0 1rem 0;

  @include desktop {
    margin: 0;
  }
}

.aboutYouBodyHeader__buttons {
  @include desktop {
    display: flex;
  }
}

.aboutYouBodyHeader__button {
  margin: 0 rem(8px) rem(8px) 0;
  width: auto !important;

  @include mobile {
    &:last-child {
      margin: 0;
    }
  }

  @include desktop {
    margin: 0 0 0 rem(8px);
  }
}

.aboutYouBodyHeader__buttonIcon {
  margin: 0 0.75rem 0 0;
}
</style>