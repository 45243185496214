<template>
  <div class="aboutYouBodyGraphHeader">
    <div class="aboutYouBodyGraphHeader__titles">
      <div
        v-for="(title, index) in titles"
        v-bind:key="index"
        class="aboutYouBodyGraphHeader__title"
      >
        <span
          v-bind:style="{ background: title.color }"
          class="aboutYouBodyGraphHeader__titleMarker"
        />

        {{ title.text }}
      </div>

      <font-awesome-icon
        v-bind:icon="['fas', 'circle-question']"
        v-on:click="$emit('SHOW_MORE_INFO')"
        class="aboutYouBodyGraphHeader__titleIcon"
      />
    </div>

    <p class="aboutYouBodyGraphHeader__body">
      {{ body }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    titles: {
      type: Array,
      required: true,
      validator: (value) => {
        return value.every(({ text, color }) => !!text && !!color);
      },
    },

    body: {
      type: String,
      required: true,
    },

    value: {
      type: String,
    },
  },

  data() {
    return {
      selectedPeriodType: this.value,
    };
  },

  methods: {
    onPeriodTypeChange(type) {
      this.selectedPeriodType = type;
      this.$emit('input', type);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.aboutYouBodyGraphHeader__titles {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 0 0.75rem 0;
}

.aboutYouBodyGraphHeader__title {
  @include paragraph--bold;
  display: flex;
  align-items: center;
  margin: 0 rem(8px) 0 0;
}

.aboutYouBodyGraphHeader__titleMarker {
  display: block;
  margin: 0 rem(8px) 0 0;
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.aboutYouBodyGraphHeader__titleIcon {
  display: block;
  color: #c9ced4;
  cursor: pointer;
}

.aboutYouBodyGraphHeader__body {
  @include note;
  margin: 0 0 1rem 0;
  color: $color-grey;
}
</style>