<template>
  <div class="aboutYouBodyHeight">
    <div class="aboutYouBodyHeight__inner">
      <div class="aboutYouBodyHeight__info">
        <div class="aboutYouBodyHeight__infoTitle">
          {{ $t('body.heightTitle') }}
        </div>

        <div class="aboutYouBodyHeight__infoValue">
          {{ $t('body.heightInCM', { height: body.height || '--' }) }}
        </div>
      </div>

      <base-button
        modifiers="secondary"
        class="aboutYouBodyHeight__button"
        v-on:click="$emit('EDIT_HEIGHT')"
      >
        {{ $t('body.heightButtonLabel') }}
      </base-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BaseButton from '@/components/BaseButton';

export default {
  components: {
    BaseButton,
  },

  computed: {
    ...mapState('member', ['body']),
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.aboutYouBodyHeight {
  position: relative;
  padding-top: 100%;

  @include desktop {
    padding: 0;
    height: 100%;
  }
}

.aboutYouBodyHeight__inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.5rem;
  border-radius: $border-radius;
  background: transparent no-repeat url('~@/assets/about-you/body/height-banner-mobile.jpg') 0 0;
  background-size: cover;

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    background-image: url('~@/assets/about-you/body/height-banner-mobile@2x.jpg');
  }

  @include desktop {
    background-image: url('~@/assets/about-you/body/height-banner-desktop.jpg');

    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      background-image: url('~@/assets/about-you/body/height-banner-desktop@2x.jpg');
    }
  }
}

.aboutYouBodyHeight__infoTitle {
  @include paragraph;
  margin: 0 0 0.25rem 0;
}

.aboutYouBodyHeight__infoValue {
  @include heading-3;
}

.aboutYouBodyHeight__button {
  align-self: flex-start;
}
</style>