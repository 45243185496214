<template>
  <div class="aboutYouBodyGraphDateSelector">
    <div class="aboutYouBodyGraphDateSelector__value">
      {{ currentValue }}
    </div>

    <div
      v-bind:class="{
        'aboutYouBodyGraphDateSelector__button': true,
        'aboutYouBodyGraphDateSelector__button--disabled': disablePrevButton,
      }"
      v-on:click="onPrevClick"
    >
      <font-awesome-icon
        v-bind:icon="['fas', 'chevron-left']"
        class="aboutYouBodyGraphDateSelector__buttonIcon"
      />
    </div>

    <div
      v-bind:class="{
        'aboutYouBodyGraphDateSelector__button': true,
        'aboutYouBodyGraphDateSelector__button--disabled': disableNextButton,
      }"
      v-on:click="onNextClick"
    >
      <font-awesome-icon
        v-bind:icon="['fas', 'chevron-right']"
        class="aboutYouBodyGraphDateSelector__buttonIcon"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    periodType: {
      type: String,
      required: true,
    },

    minDate: {
      type: Date,
      required: true,
    },

    modelValue: {
      type: Date,
      required: true,
    },
  },

  computed: {
    currentValue() {
      if (this.periodType === 'year') {
        return this.modelValue.getFullYear();
      }

      return this.modelValue.toLocaleString('nl-NL', {
        month: 'long',
        year: 'numeric',
      });
    },

    disablePrevButton() {
      const isSameYear = this.modelValue.getFullYear() === this.minDate.getFullYear();

      if (this.periodType === 'year') {
        return isSameYear;
      }

      const isSameMonth = this.modelValue.getMonth() === this.minDate.getMonth();

      return isSameYear && isSameMonth;
    },

    disableNextButton() {
      const now = new Date();
      const isSameYear = this.modelValue.getFullYear() === now.getFullYear();

      if (this.periodType === 'year') {
        return isSameYear;
      }

      const isSameMonth = this.modelValue.getMonth() === now.getMonth();

      return isSameYear && isSameMonth;
    },
  },

  methods: {
    onPrevClick() {
      if (this.disablePrevButton) {
        return;
      }

      let selectedDate = new Date(this.modelValue);

      switch (this.periodType) {
        case 'year':
          selectedDate.setFullYear(selectedDate.getFullYear() - 1);
          break;

        case 'month':
          selectedDate.setMonth(selectedDate.getMonth() - 1);
          break;
      }

      this.$emit('update:modelValue', selectedDate);
    },

    onNextClick() {
      if (this.disableNextButton) {
        return;
      }

      let selectedDate = new Date(this.modelValue);

      switch (this.periodType) {
        case 'year':
          selectedDate.setFullYear(selectedDate.getFullYear() + 1);
          break;

        case 'month':
          selectedDate.setMonth(selectedDate.getMonth() + 1);
          break;
      }

      this.$emit('update:modelValue', selectedDate);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.aboutYouBodyGraphDateSelector {
  display: flex;
  align-items: center;
}

.aboutYouBodyGraphDateSelector__value {
  @include paragraph;
  margin: 0 0.75rem 0 0;
}

.aboutYouBodyGraphDateSelector__button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.5rem 0 0;
  width: 1.5rem;
  height: 1.5rem;
  background: $color-beige;
  border-radius: 50%;
  cursor: pointer;

  &:hover:not(.aboutYouBodyGraphDateSelector__button--disabled) {
    background: $color-beige--dark;
  }

  &--disabled {
    opacity: 0.5;
    cursor: default;
  }

  &:last-child {
    margin: 0;
  }
}

.aboutYouBodyGraphDateSelector__buttonIcon {
  color: $color-text;
  font-size: 0.8125rem;
}
</style>