<template>
  <div class="aboutYouBodySummary">
    <div class="aboutYouBodySummary__item">
      <about-you-body-summary-bmi
        v-bind:body-measurements="bodyMeasurements"
        v-on:SHOW_MORE_INFO="$emit('SHOW_BMI_INFO')"
      />
    </div>

    <div class="aboutYouBodySummary__item">
      <about-you-body-summary-bmr
        v-bind:body-measurements="bodyMeasurements"
        v-on:SHOW_MORE_INFO="$emit('SHOW_BMR_INFO')"
      />
    </div>

    <div class="aboutYouBodySummary__item">
      <about-you-body-summary-target-weight
        v-bind:body-measurements="bodyMeasurements"
      />
    </div>
  </div>
</template>

<script>
import AboutYouBodySummaryBmi from '@/components/aboutyou/body/AboutYouBodySummaryBmi';
import AboutYouBodySummaryBmr from '@/components/aboutyou/body/AboutYouBodySummaryBmr';
import AboutYouBodySummaryTargetWeight from '@/components/aboutyou/body/AboutYouBodySummaryTargetWeight';

export default {
  components: {
    AboutYouBodySummaryBmi,
    AboutYouBodySummaryBmr,
    AboutYouBodySummaryTargetWeight,
  },

  props: {
    bodyMeasurements: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.aboutYouBodySummary {
  padding: 0;
  border: 1px solid rgba(#222, 0.08);
  border-radius: $border-radius;
  box-shadow: 0px 2px 6px 0px rgba(#000, 0.06);

  @include desktop {
    display: flex;
    justify-content: space-evenly;
    padding: 1rem 0;
  }
}

.aboutYouBodySummary__item {
  padding: 1rem;
  border-bottom: 1px solid rgba(#222, 0.08);

  @include desktop {
    flex: 0 0 33.3%;
    padding: 0.5rem 1.5rem;
    width: 33.3%;
    border-right: 1px solid rgba(#222, 0.08);
    border-bottom: 0;
  }

  &:last-child {
    border: 0;
  }
}
</style>