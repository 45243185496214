/**
 *
 * @param {Date} selectedDate
 * @param {String} period (year or month)
 * @returns {Array}
 */
const getChartLabels = (selectedDate, period) => {
  const currentYear = selectedDate.getFullYear();

  if (period === 'year') {
    return new Array(12)
      .fill(0)
      .map((value, index) => new Date(currentYear, index, 1));
  }

  const currentMonth = selectedDate.getMonth();
  const numDaysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

  return new Array(numDaysInMonth)
    .fill(0)
    .map((value, index) => new Date(currentYear, currentMonth, index + 1));
};

export default getChartLabels;