<template>
  <div class="aboutYouBodySummaryTargetWeight">
    <div class="aboutYouBodySummaryTargetWeight__title">
      {{ $t('body.targetWeightInfoTitle') }}
    </div>

    <div class="aboutYouBodySummaryTargetWeight__value">
      {{ renderedValue }} Kg
    </div>

    <div ref="graph" class="aboutYouBodySummaryTargetWeight__graph">
      <div class="aboutYouBodySummaryTargetWeight__graphBar">
        <div
          v-if="showScale"
          v-bind:style="{ left: `${percentage}%` }"
          class="aboutYouBodySummaryTargetWeight__graphBarMarker"
        />

        <div
          v-if="showScale"
          class="aboutYouBodySummaryTargetWeight__graphBarLabels"
          v-bind:style="{ background: `linear-gradient(${linearGradient})` }"
        />

        <div
          v-bind:class="{
            'aboutYouBodySummaryTargetWeight__graphBarToolTip': true,
            'aboutYouBodySummaryTargetWeight__graphBarToolTip--visible': isToolTipVisible,
          }"
          v-bind:style="{ left: `${percentage}%`}"
        >
          {{ weight }} Kg
        </div>
      </div>

      <div
        v-if="showScale"
        class="aboutYouBodySummaryTargetWeight__graphLabels"
      >
        <span
          v-for="(label, index) in labels"
          v-bind:key="index"
          v-bind:style="getLabelStyles(index)"
          class="aboutYouBodySummaryTargetWeight__graphLabel"
        >
          {{ label }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';
import drawGraphBarLinearGradient from '@/utils/drawGraphBarLinearGradient';
import parseDate from '@/utils/parseDate';

export default {
  props: {
    bodyMeasurements: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      minMaxOffset: 5,
      isToolTipVisible: false,
    };
  },

  computed: {
    ...mapState('member', ['body']),

    renderedValue() {
      if (this.targetWeight) {
        return this.targetWeight;
      }

      return '--';
    },

    showScale() {
      const minValue = this.labels[0];
      const maxValue = this.labels[this.labels.length - 1];

      return this.targetWeight && this.weight > minValue && this.weight < maxValue;
    },

    targetWeight() {
      return this.body.target_weight || 0;
    },

    maxWeight() {
      const weights = this.bodyMeasurements.history
        // .filter(({ date }) => moment().isSame(parseDate(date), 'year'))
        .map(({ weight }) => weight);

      return Math.ceil(Math.max(Math.max(...weights), this.targetWeight + this.minMaxOffset));
    },

    weight() {
      const numMeasurementsLength = this.bodyMeasurements.history.length;

      if (!numMeasurementsLength) {
        return 0;
      }

      const { weight } = this.bodyMeasurements.history[numMeasurementsLength - 1];

      return weight;
    },

    percentage() {
      const minValue = this.targetWeight - this.minMaxOffset;
      const maxValue = this.maxWeight + this.minMaxOffset;

      return ((this.weight - minValue) * 100) / (maxValue - minValue);
    },

    labels() {
      const minValue = this.targetWeight - this.minMaxOffset;
      const maxValue = this.maxWeight + this.minMaxOffset;
      const scale = (maxValue - minValue) / 3;

      return [
        Math.round(minValue),
        Math.round(scale + minValue),
        Math.round((scale * 2) + minValue),
        Math.round(maxValue),
      ];
    },

    linearGradient() {
      const labels = this.labels.map(label => ({
        value: label,
        colour: 'transparent',
      }));

      return drawGraphBarLinearGradient(labels);
    },
  },

  mounted() {
    this.$refs.graph.addEventListener('mouseover', this.toggleToolTip);
    this.$refs.graph.addEventListener('mouseout', this.toggleToolTip);
  },

  methods: {
    toggleToolTip(event) {
      this.isToolTipVisible = event.type === 'mouseover';
    },

    getLabelStyles(index) {
      const percentage = (100 / (this.labels.length - 1)) * index;

      return { left: `${percentage}%` };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.aboutYouBodySummaryTargetWeight__title {
  @include small;
  margin: 0 0 0.5rem 0;
  color: $color-grey;
}

.aboutYouBodySummaryTargetWeight__value {
  @include heading-3;
  margin: 0 0 1rem 0;
}

.aboutYouBodySummaryTargetWeight__graph {
  margin: 0 0 1.125rem;
}

.aboutYouBodySummaryTargetWeight__graphBar {
  position: relative;
  height: 0.5rem;
  border-radius: 0.25rem;
  background: #6a77cc,
}

.aboutYouBodySummaryTargetWeight__graphBarMarker {
  position: absolute;
  top: -0.25rem;
  width: 0.125rem;
  height: 1rem;
  background: $color-black;
  z-index: 1;
}

.aboutYouBodySummaryTargetWeight__graphBarLabels {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.aboutYouBodySummaryTargetWeight__graphBarToolTip {
  display: none;
  position: absolute;
  top: -2.5rem;
  padding: 0.5rem 0.75rem;
  border-radius: $border-radius;
  background: rgba($color-black, .8);
  color: $color-white;
  transform: translateX(-50%);

  &--visible {
    display: block;
  }
}

.aboutYouBodySummaryTargetWeight__graphLabels {
  position: relative;
}

.aboutYouBodySummaryTargetWeight__graphLabel {
  @include note;
  position: absolute;
  top: 0.25rem;
  transform: translateX(-50%);

  &:first-child {
    transform: translateX(0);
  }

  &:last-child {
    transform: translateX(-100%);
  }
}
</style>