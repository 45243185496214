<template>
  <div class="aboutYouBody">
    <base-section>
      <base-grid modifiers="maxWidth">
        <base-row>
          <base-column>
            <about-you-body-header v-on:UPDATED_BODY_MEASUREMENTS="onBodyMeasurementsUpdated" />
          </base-column>
        </base-row>
      </base-grid>
    </base-section>

    <base-section v-if="!isLoading">
      <base-grid modifiers="maxWidth">
        <base-row>
          <base-column>
            <about-you-body-summary
              v-bind:body-measurements="body"
              v-on:SHOW_BMI_INFO="onShowBmiInfoModal"
              v-on:SHOW_BMR_INFO="onShowBmrInfoModal"
              class="aboutYouBody__summary"
            />
          </base-column>
        </base-row>
      </base-grid>
    </base-section>

    <base-section v-if="!isLoading">
      <base-grid modifiers="maxWidth">
        <base-row>
          <base-column>
            <div class="aboutYouBody__periodToggle">
              <about-you-body-graph-tabs v-model="selectedPeriodType" />

              <about-you-body-graph-date-selector
                v-bind:period-type="selectedPeriodType"
                v-bind:min-date="minDate"
                v-model="selectedDate"
              />
            </div>
          </base-column>
        </base-row>
      </base-grid>
    </base-section>

    <base-section v-if="!isLoading">
      <base-grid modifiers="maxWidth">
        <base-row>
          <base-column>
            <about-you-body-weight
              v-bind:body-measurements="body"
              v-bind:selected-date="selectedDate"
              v-bind:selected-period-type="selectedPeriodType"
              v-on:SHOW_WEIGHT_INFO="onShowWeightInfoModal"
              class="aboutYouBody__weight"
            />
          </base-column>
        </base-row>
      </base-grid>
    </base-section>

    <base-section v-if="!isLoading">
      <base-grid modifiers="maxWidth">
        <base-row modifiers="stretch">
          <base-column modifiers="sm-8">
            <about-you-body-hip-waist
              v-bind:body-measurements="body"
              v-bind:selected-date="selectedDate"
              v-bind:selected-period-type="selectedPeriodType"
              v-on:SHOW_HIP_WAIST_INFO="onShowHipWaistInfoModal"
              class="aboutYouBody__hipWaist"
            />
          </base-column>

          <base-column modifiers="sm-4">
            <about-you-body-height v-on:EDIT_HEIGHT="onShowHeightModal" />
          </base-column>
        </base-row>
      </base-grid>
    </base-section>
  </div>
</template>

<script>
import { defineAsyncComponent, markRaw } from 'vue';
import { mapActions, mapState } from 'vuex';
import BaseSection from '@/components/grid/BaseSection';
import BaseGrid from '@/components/grid/BaseGrid';
import BaseRow from '@/components/grid/BaseRow';
import BaseColumn from '@/components/grid/BaseColumn';
import AboutYouBodyHeader from '@/components/aboutyou/body/AboutYouBodyHeader';
import AboutYouBodySummary from '@/components/aboutyou/body/AboutYouBodySummary';
import AboutYouBodyGraphTabs from '@/components/aboutyou/body/AboutYouBodyGraphTabs';
import AboutYouBodyGraphDateSelector from '@/components/aboutyou/body/AboutYouBodyGraphDateSelector';
import AboutYouBodyWeight from '@/components/aboutyou/body/AboutYouBodyWeight';
import AboutYouBodyHipWaist from '@/components/aboutyou/body/AboutYouBodyHipWaist';
import AboutYouBodyHeight from '@/components/aboutyou/body/AboutYouBodyHeight';
import { ModalBus } from '@/eventBus';

const BmiInfoModal = markRaw(defineAsyncComponent({
  loader: () => import('@/components/aboutyou/body/BmiInfoModal' /* webpackChunkName: "bmiInfoModal" */),
}));

const BmrInfoModal = markRaw(defineAsyncComponent({
  loader: () => import('@/components/aboutyou/body/BmrInfoModal' /* webpackChunkName: "bmrInfoModal" */),
}));

const WeightInfoModal = markRaw(defineAsyncComponent({
  loader: () => import('@/components/aboutyou/body/WeightInfoModal' /* webpackChunkName: "weightInfoModal" */),
}));

const HipWaistInfoModal = markRaw(defineAsyncComponent({
  loader: () => import('@/components/aboutyou/body/HipWaistInfoModal' /* webpackChunkName: "hipWaistInfoModal" */),
}));

const BodyMeasurementsHeightModal = markRaw(defineAsyncComponent({
  loader: () => import('@/components/aboutyou/body/BodyMeasurementsHeightModal' /* webpackChunkName: "bodyMeasurementsHeightModal" */),
}));

export default {
  components: {
    BaseSection,
    BaseGrid,
    BaseRow,
    BaseColumn,
    AboutYouBodyHeader,
    AboutYouBodySummary,
    AboutYouBodyGraphTabs,
    AboutYouBodyGraphDateSelector,
    AboutYouBodyWeight,
    AboutYouBodyHipWaist,
    AboutYouBodyHeight,
  },

  data() {
    return {
      isLoading: false,
      pollInterval: null,
      selectedDate: new Date(),
      selectedPeriodType: 'year',
    };
  },

  computed: {
    ...mapState('member', ['member', 'body']),

    minDate() {
      return new Date(this.member.start_date);
    },
  },

  watch: {
    selectedPeriodType() {
      this.fetchData();
    },

    selectedDate() {
      this.fetchData();
    },
  },

  created() {
    this.fetchData();
  },

  mounted() {
    const intervalTime = 5 * 60 * 1000; // 5 minutes

    this.pollInterval = setInterval(() => {
      const fetchData = {
        year: this.selectedDate.getFullYear(),
        month: this.selectedPeriodType === 'month' ? this.selectedDate.getMonth() + 1 : null,
      };

      this.pollMemberBody(fetchData);
    }, intervalTime);
  },

  beforeUnmount() {
    clearInterval(this.pollInterval);
  },

  methods: {
    ...mapActions({
      fetchMember: 'member/fetch',
      fetchMemberBody: 'member/fetchBody',
      pollMemberBody: 'member/pollBody',
    }),

    async fetchData() {
      this.isLoading = true;

      const fetchData = {
        year: this.selectedDate.getFullYear(),
        month: this.selectedPeriodType === 'month' ? this.selectedDate.getMonth() + 1 : null,
      };

      await this.fetchMemberBody(fetchData);

      this.isLoading = false;
    },

    onBodyMeasurementsUpdated() {
      this.fetchMember();
      this.fetchData();
    },

    onShowBmiInfoModal() {
      ModalBus.emit('open', {
        component: BmiInfoModal,
      });
    },

    onShowBmrInfoModal() {
      ModalBus.emit('open', {
        component: BmrInfoModal,
      });
    },

    onShowWeightInfoModal() {
      ModalBus.emit('open', {
        component: WeightInfoModal,
      });
    },

    onShowHipWaistInfoModal() {
      ModalBus.emit('open', {
        component: HipWaistInfoModal,
      });
    },

    onShowHeightModal() {
      ModalBus.emit('open', {
        component: BodyMeasurementsHeightModal,
        props: {
          selectedDate: this.selectedDate,
          selectedPeriodType: this.selectedPeriodType,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.aboutYouBody__periodToggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
