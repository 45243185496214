<template>
  <div class="aboutYouBodySummaryBmr">
    <div class="aboutYouBodySummaryBmr__title">
      {{ $t('body.bmrInfoTitle') }}

      <font-awesome-icon
        v-bind:icon="['fas', 'circle-question']"
        v-on:click="$emit('SHOW_MORE_INFO')"
        class="aboutYouBodySummaryBmr__titleIcon"
      />
    </div>

    <div class="aboutYouBodySummaryBmr__value">
      {{ renderedValue }}
    </div>

    <div class="aboutYouBodySummaryBmr__graphWrapper">
      <Line
        v-bind:chart-data="dataCollection"
        v-bind:chart-options="options"
        v-bind:styles="styles"
      />
    </div>
  </div>
</template>

<script>
import { Line } from 'vue-chartjs';
import { Chart as ChartJS, LineElement, PointElement, CategoryScale, LinearScale } from 'chart.js';
import aboutYouBodyBmrGraphOptions from '@/config/aboutYouBodyBmrGraphOptions';

ChartJS.register(LineElement, PointElement, CategoryScale, LinearScale);

export default {
  components: {
    Line,
  },

  props: {
    bodyMeasurements: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      dataCollection: {
        labels: [],
        datasets: [],
      },
      options: aboutYouBodyBmrGraphOptions,
      styles: {
        width: '8rem',
        height: '1.625rem',
      },
    };
  },

  computed: {
    renderedValue() {
      if (this.bmr) {
        return this.bmr.toFixed(1);
      }

      return '--';
    },

    bmr() {
      const numMeasurementsLength = this.bodyMeasurements.history.length;

      if (!numMeasurementsLength) {
        return 0;
      }

      const { bmr } = this.bodyMeasurements.history[numMeasurementsLength - 1];

      return bmr;
    },
  },

  watch: {
    bodyMeasurements() {
      this.updateData();
    },
  },

  mounted() {
    this.updateData();
  },

  methods: {
    updateData() {
      if (!this.bmr) {
        return;
      }

      const values = this.bodyMeasurements.history.map(({ bmr }) => bmr);
      const lastValues = values.slice(Math.max(values.length - 6, 0));

      this.dataCollection = {
        labels: new Array(lastValues.length).fill(0),
        datasets: [{
          data: lastValues,
          borderColor: '#1860af',
        }],
      };
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.aboutYouBodySummaryBmr__title {
  @include small;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 0.5rem 0;
  color: $color-grey;
}

.aboutYouBodySummaryBmr__titleIcon {
  display: block;
  color: #c9ced4;
  cursor: pointer;
}

.aboutYouBodySummaryBmr__value {
  @include heading-3;
  margin: 0 0 1rem 0;
}

.aboutYouBodySummaryBmr__graphWrapper {
  display: flex;
  justify-content: flex-end;
  padding: 0 1.5rem 0 0;
}
</style>