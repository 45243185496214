<template>
  <div class="aboutYouBodyWeight">
    <div class="aboutYouBodyWeight__header">
      <about-you-body-graph-header
        v-bind:titles="[{ text: $t('body.weightTitle'), color: '#3271d1' }]"
        v-on:SHOW_MORE_INFO="$emit('SHOW_WEIGHT_INFO')"
        v-bind:body="$t('body.weightGraphUnit')"
      />
    </div>

    <Line
      v-bind:chart-data="dataCollection"
      v-bind:chart-options="options"
    />
  </div>
</template>

<script>
import { Line } from 'vue-chartjs';
import { Chart as ChartJS, LinearScale, TimeScale, Tooltip } from 'chart.js';
import 'chartjs-adapter-moment';
import AboutYouBodyGraphHeader from '@/components/aboutyou/body/AboutYouBodyGraphHeader';
import aboutYouBodyGraphDefaultOptions from '@/config/aboutYouBodyGraphDefaultOptions';
import getChartLabels from '@/utils/getChartLabels';
import getChartData from '@/utils/getChartData';

ChartJS.register(LinearScale, TimeScale, Tooltip);

export default {
  components: {
    Line,
    AboutYouBodyGraphHeader,
  },

  props: {
    bodyMeasurements: {
      type: Object,
      required: true,
    },

    selectedDate: {
      type: Date,
      required: true,
    },

    selectedPeriodType: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      dataCollection: {
        labels: [],
        datasets: [],
      },
      options: aboutYouBodyGraphDefaultOptions({
        tooltipUnit: 'Kg',
        stepSize: 1,
      }),
    };
  },

  watch: {
    bodyMeasurements() {
      this.updateData();
    },
  },

  mounted() {
    this.updateData();
  },

  methods: {
    updateData() {
      const labels = getChartLabels(this.selectedDate, this.selectedPeriodType);
      const weights = getChartData(this.bodyMeasurements.history, 'weight', this.selectedDate, this.selectedPeriodType);

      this.dataCollection = {
        datasets: [{ data: weights, borderColor: '#1860af', spanGaps: true }],
        labels,
      };

      // filter out null values to define the y-axes scale
      const measuredWeights = weights.filter(weight => !!weight);
      let minTicks = Math.floor(Math.min(...measuredWeights) - 0.5);
      let maxTicks = Math.ceil(Math.max(...measuredWeights) + 0.5);

      if (!measuredWeights.length) {
        // fallback value is around the (Dutch) average for women
        const averageValue = 70;

        minTicks = averageValue - 5;
        maxTicks = averageValue + 5;
      }

      this.options.scales.y.ticks.min = minTicks;
      this.options.scales.y.ticks.max = maxTicks;

      this.options.scales.x.time.unit = this.selectedPeriodType === 'year' ? 'month' : 'day';
      this.options.scales.x.time.tooltipFormat = this.selectedPeriodType === 'year' ? 'MMMM' : 'D MMMM';

      // force options to be updated
      this.options = { ...this.options };
    },
  },
};
</script>