<template>
  <component
    v-bind="{ ...$attrs, ...buttonProps }"
    v-bind:is="buttonTag"
    v-bind:class="{
      [blockName]: true,
      [modifierClasses]: true,
    }"
  >
    <div v-if="showSpinner" class="baseButton__spinner">
      <svg viewBox="0 0 30 30" class="baseButton__spinner__icon"><use xlink:href="../assets/icons.svg#spinner" /></svg>
    </div>

    <slot v-if="!showSpinner || !loadingText" />

    {{ showSpinner && loadingText ? loadingText : '' }}

    <span
      v-if="isLoading"
      class="baseButton__loadingDots"
    >
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </span>
  </component>
</template>

<script>
import modifiers from '@/mixins/modifiers';

export default {
  mixins: [
    modifiers([
      'primary',
      'secondary',
      'tertiary',
      'white',
      'link',
      'skipWorkout',
      'block',
      'fullWidth',
      'small',
    ])
  ],

  props: {
    to: {
      type: Object,
      default: null,
    },

    href: {
      type: String,
      default: '',
    },

    target: {
      type: String,
      default: '_self',
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    showSpinner: {
      type: Boolean,
      default: false,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    loadingText: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      blockName: 'baseButton',
    };
  },

  computed: {
    buttonTag() {
      if (this.to) {
        return 'router-link';
      }

      if (this.href) {
        return 'a';
      }

      return 'button';
    },

    buttonProps() {
      if (this.to) {
        return {
          to: this.to
        };
      }

      if (this.href) {
        return {
          href: this.href,
          target: this.target,
        };
      }

      return {
        disabled: this.disabled || this.showSpinner,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.baseButton {
  @include paragraph--semibold;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: rem(10px) rem(20px);
  border-radius: rem(22px);
  border: none;
  background: none;
  text-decoration: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  appearance: none;
  transition: background 0.35s, color 0.35s;

  &--block {
    display: flex;
  }

  &--fullWidth {
    display: flex;
    width: 100%;
  }

  &--small {
    @include label--bold;
    padding: rem(9px) rem(15px);
  }

  &--primary {
    color: $color-button-primary-label;
    background-color: $color-button-primary-background;

    &:hover:not(:disabled) {
      background-color: $color-button-primary-background-hover;
    }
  }

  &--secondary {
    color: $color-button-secondary-label;
    background-color: $color-button-secondary-background;

    &:hover:not(:disabled) {
      background-color: $color-button-secondary-background-hover;
    }
  }

  &--tertiary {
    color: $color-button-tertiary-label;
    background-color: $color-button-tertiary-background;

    &:hover:not(:disabled) {
      background-color: $color-button-tertiary-background-hover;
    }
  }

  &--white {
    color: $color-button-white-label;
    background-color: $color-button-white-background;

    &:hover:not(:disabled) {
      background-color: $color-button-white-background-hover;
    }
  }

  &:disabled {
    cursor: default;
    background-color: $color-button-disabled-background;
    color: $color-button-disabled-label;
  }

  &--link {
    width: auto;
    color: $color-text-link;
    background: transparent;
  }

  &--skipWorkout {
    border: 1px solid $color-grey--medium;
    color: $color-grey--medium;

    @include desktop {
      color: $color-white;
      border: 1px solid $color-white;
      opacity: 0.4;

      &:hover:not(:disabled) {
        opacity: 1;
      }
    }
  }
}

.baseButton__spinner {
  margin: 0 8px 0 0;
  width: 13px;
  height: 13px;
  flex: 0 0 auto;
  vertical-align: middle;
  display: inline-block;
}

.baseButton__spinner__icon {
  position: relative;
  top: -5px;
  color: inherit;
  transform-origin: 50%;
  animation: baseButton-spin-animation 2s linear infinite;

  .baseButton--link & {
    top: -3px;
  }
}

.baseButton__loadingDots {
  display: flex;

  span {
    animation: 0.6s infinite baseButton-loadingdots-animation;

    &:nth-child(2n) {
      animation-delay: 0.35s;
    }

    &:nth-child(3n) {
      animation-delay: 0.4s;
    }
  }
}

@keyframes baseButton-spin-animation {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes baseButton-loadingdots-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
