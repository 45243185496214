import capitalize from '@/filters/capitalize';

const aboutYouBodyGraphDefaultOptions = ({ tooltipUnit, stepSize }) => {
  const fontFamily = 'Inter, Helvetica, sans-serif';

  return {
    maintainAspectRatio: false,
    elements: {
      point: {
        radius: 3.5,
        borderWidth: 3,
        backgroundColor: '#fff',
      },
      line: {
        tension: 0,
        fill: false,
      },
    },
    scales: {
      y: {
        grid: {
          drawBorder: false,
          borderDash: [2, 3],
        },
        ticks: {
          stepSize,
          font: fontFamily,
        },
      },
      x: {
        type: 'time',
        time: {
          unit: 'month',
          displayFormats: {
            day: 'D',
            month: 'MMM',
          },
          tooltipFormat: 'MMMM',
        },
        grid: {
          display: false,
        },
        ticks: {
          font: fontFamily,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        padding: {
          x: 12,
          y : 8,
        },
        caretPadding: 8,
        displayColors: false,
        titleFont: {
          family: fontFamily,
          size: 12,
        },
        titleColor: 'rgba(255, 255, 255, 0.6)',
        bodyFont: {
          family: fontFamily,
          weight: 'bold',
          size: 15,
        },
        callbacks: {
          title(tooltipItem) {
            return capitalize(tooltipItem[0].label);
          },
          label(tooltipItem) {
            return `${tooltipItem.formattedValue} ${tooltipUnit}`;
          },
        },
      },
    },
  };
};

export default aboutYouBodyGraphDefaultOptions;