import moment from 'moment';

/**
 *
 * @param {Array} data
 * @param {String} property (i.e. weight)
 * @param {Date} selectedDate
 * @param {String} period (year or month)
 * @returns {Array}
 */
const getChartData = (data, property, selectedDate, period) => {
  const isYearPeriod = period === 'year';

  // get all values for the given time frame
  const periodValues = data
    .map(value => ({
      [property]: value[property],
      date: new Date(value.date),
    }))
    .reduce((acc, value) => {
      const keyDateFuntion = isYearPeriod ? 'getMonth' : 'getDate';
      const key = value.date[keyDateFuntion]() + (isYearPeriod ? 1 : 0);

      return {
        ...acc,
        [key]: value,
      };
    }, {});

  // create object with keys for the entire period
  const numValues = isYearPeriod ? 12 : moment(selectedDate).daysInMonth();
  const nullValues = new Array(numValues)
    .fill(null)
    .reduce((acc, value, index) => ({ ...acc, [index + 1]: { [property]: null } }), {});

  // merge nullValues and periodValues to get all values up to
  // the current day or month and convert to an array of values
  const allValuesObject = {
    ...nullValues,
    ...periodValues,
  };

  const allValues = Object
    .keys(allValuesObject)
    .reduce((acc, key) => {
      return [...acc, allValuesObject[key][property]];
    }, []);

  return allValues;
};

export default getChartData;