<template>
  <div class="aboutYouBodyWeight">
    <div class="aboutYouBodyWeight__header">
      <about-you-body-graph-header
        v-bind:titles="titles"
        v-on:SHOW_MORE_INFO="$emit('SHOW_HIP_WAIST_INFO')"
        v-bind:body="$t('body.hipWaistGraphUnit')"
      />
    </div>

    <Line
      v-bind:chart-data="dataCollection"
      v-bind:chart-options="options"
    />
  </div>
</template>

<script>
import { Line } from 'vue-chartjs';
import { Chart as ChartJS, LinearScale, TimeScale, Tooltip } from 'chart.js';
import 'chartjs-adapter-moment';
import AboutYouBodyGraphHeader from '@/components/aboutyou/body/AboutYouBodyGraphHeader';
import aboutYouBodyGraphDefaultOptions from '@/config/aboutYouBodyGraphDefaultOptions';
import getChartLabels from '@/utils/getChartLabels';
import getChartData from '@/utils/getChartData';

ChartJS.register(LinearScale, TimeScale, Tooltip);

export default {
  components: {
    Line,
    AboutYouBodyGraphHeader,
  },

  props: {
    bodyMeasurements: {
      type: Object,
      required: true,
    },

    selectedDate: {
      type: Date,
      required: true,
    },

    selectedPeriodType: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      titles: [
        { text: this.$t('body.hipTitle'), color: '#f5b962' },
        { text: this.$t('body.waistTitle'), color: '#006856' },
      ],
      dataCollection: {
        labels: [],
        datasets: [],
      },
      options: aboutYouBodyGraphDefaultOptions({
        tooltipUnit: 'cm',
        stepSize: 1,
      }),
    };
  },

  watch: {
    bodyMeasurements() {
      this.updateData();
    },
  },

  mounted() {
    this.updateData();
  },

  methods: {
    updateData() {
      const labels = getChartLabels(this.selectedDate, this.selectedPeriodType);
      const hip = getChartData(this.bodyMeasurements.history, 'hip', this.selectedDate, this.selectedPeriodType);
      const waist = getChartData(this.bodyMeasurements.history, 'waist', this.selectedDate, this.selectedPeriodType);

      this.dataCollection = {
        datasets: [
          { data: waist, borderColor: '#f5b962', spanGaps: true },
          { data: hip, borderColor: '#006856', spanGaps: true },
        ],
        labels,
      };

      // filter out null values to define the y-axes scale
      const measuredHipSizes = hip.filter(value => !!value);
      const measuredWaistSizes = waist.filter(value => !!value);
      let minTicks = Math.floor(Math.min(...measuredHipSizes, ...measuredWaistSizes) - 0.5);
      let maxTicks = Math.ceil(Math.max(...measuredHipSizes, ...measuredWaistSizes) + 0.5);

      if (!measuredHipSizes.length && !measuredWaistSizes.length) {
        // fallback value is around the (Dutch) average for women
        const averageValue = 80;

        minTicks = averageValue - 5;
        maxTicks = averageValue + 5;
      }

      this.options.scales.y.ticks.min = minTicks;
      this.options.scales.y.ticks.max = maxTicks;

      this.options.scales.x.time.unit = this.selectedPeriodType === 'year' ? 'month' : 'day';
      this.options.scales.x.time.tooltipFormat = this.selectedPeriodType === 'year' ? 'MMMM' : 'D MMMM';

      // force options to be updated
      this.options = { ...this.options };
    },
  },
};
</script>