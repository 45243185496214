import getPercentageInRange from '@/utils/getPercentageInRange';

const renderColourStop = (colour, start, end) => `, ${colour} ${start}, ${colour} ${end}`;

/**
 *
 * @param {Array} data { value: Number, color: String }
 * @returns {String}
 */
const drawGraphBarLinearGradient = (data) => {
  const min = data[0].value;
  const max = data[data.length - 1].value;

  return data.reduce((acc, { value }, index, array) => {
    const percentage = `${getPercentageInRange(value, min, max)}%`;
    let returnValue = `${acc}`;

    if (index !== 0) {
      const { value: previousValue, colour } = array[index - 1];
      const previousPercentage = `${getPercentageInRange(previousValue, min, max)}%`;

      returnValue += renderColourStop(colour, previousPercentage, percentage);

      if (index !== array.length - 1) {
        returnValue += renderColourStop('#fff', percentage, `calc(${percentage} + 2px)`);
      }
    }

    return `${returnValue}`;
  }, 'to right');
};

export default drawGraphBarLinearGradient;