<template>
  <div class="aboutYouBodySummaryBmi">
    <div class="aboutYouBodySummaryBmi__title">
      {{ $t('body.bmiInfoTitle') }}

      <font-awesome-icon
        v-bind:icon="['fas', 'circle-question']"
        v-on:click="$emit('SHOW_MORE_INFO')"
        class="aboutYouBodySummaryBmi__titleIcon"
      />
    </div>

    <div class="aboutYouBodySummaryBmi__info">
      <span class="aboutYouBodySummaryBmi__infoValue">
        {{ renderedValue }}
      </span>

      <span class="aboutYouBodySummaryBmi__infoMeaning">
        {{ valueMeaning }}
      </span>
    </div>

    <div
      v-bind:style="{ background: `linear-gradient(${linearGradient})` }"
      class="aboutYouBodySummaryBmi__graph"
    >
      <div class="aboutYouBodySummaryBmi__graphLabels">
        <span
          v-for="({ value }, index) in labels"
          v-bind:key="index"
          v-bind:style="{ left: `${getPercentageInRange(value, minValue, maxValue)}%` }"
          class="aboutYouBodySummaryBmi__graphLabel"
        >
          {{ value }}
        </span>
      </div>

      <div
        v-if="bmi > minValue && bmi < maxValue"
        v-bind:style="{ left: `${percentage}%` }"
        class="aboutYouBodySummaryBmi__graphMarker"
      />
    </div>
  </div>
</template>

<script>
import drawGraphBarLinearGradient from '@/utils/drawGraphBarLinearGradient';
import getPercentageInRange from '@/utils/getPercentageInRange';

const labels = [
  { value: 16, colour: '#c9ced4' },
  { value: 18.5, colour: '#5ccc5c' },
  { value: 25, colour: '#ff896b' },
  { value: 30, colour: '#bd3817' },
  { value: 36, colour: '#bd3817' },
];

export default {
  data() {
    return {
      minValue: labels[0].value,
      maxValue: labels[labels.length - 1].value,
      labels,
    };
  },

  props: {
    bodyMeasurements: {
      type: Object,
      required: true,
    },
  },

  computed: {
    renderedValue() {
      if (this.bmi) {
        return this.bmi.toFixed(1);
      }

      return '--';
    },

    bmi() {
      const numMeasurementsLength = this.bodyMeasurements.history.length;

      if (!numMeasurementsLength) {
        return 0;
      }

      const { bmi } = this.bodyMeasurements.history[numMeasurementsLength - 1];

      return bmi;
    },

    percentage() {
      return getPercentageInRange(this.bmi, this.minValue, this.maxValue);
    },

    valueMeaning() {
      if (!this.bmi) {
        return '';
      }

      switch (true) {
        case this.bmi < 18.5:
          return this.$t('body.bmiInfoWeightValue1');

        case this.bmi < 25:
          return this.$t('body.bmiInfoWeightValue2');

        case this.bmi < 30:
          return this.$t('body.bmiInfoWeightValue3');

        case this.bmi > 30:
          return this.$t('body.bmiInfoWeightValue4');
      }
    },

    linearGradient() {
      return drawGraphBarLinearGradient(this.labels);
    },
  },

  methods: {
    getPercentageInRange,
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.aboutYouBodySummaryBmi__title {
  @include small;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 0.5rem 0;
  color: $color-grey;
}

.aboutYouBodySummaryBmi__titleIcon {
  display: block;
  color: #c9ced4;
  cursor: pointer;
}

.aboutYouBodySummaryBmi__info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 1rem 0;
}

.aboutYouBodySummaryBmi__infoValue {
  @include heading-3;
}

.aboutYouBodySummaryBmi__infoMeaning {
  @include small;
}

.aboutYouBodySummaryBmi__graph {
  position: relative;
  margin: 0 0 1.125rem;
  height: 0.5rem;
  border-radius: 0.25rem;
}

.aboutYouBodySummaryBmi__graphLabel {
  @include note;
  position: absolute;
  bottom: -1.5rem;
  transform: translateX(-50%);

  &:first-child {
    transform: translateX(0);
  }

  &:last-child {
    transform: translateX(-100%);
  }
}

.aboutYouBodySummaryBmi__graphMarker {
  position: absolute;
  top: -0.25rem;
  width: 0.125rem;
  height: 1rem;
  background: $color-black;
  z-index: 1;
}
</style>