const options = {
  maintainAspectRatio: false,
  responsive: true,
  layout: {
    padding: {
      top: 0,
      right: 5,
      bottom: 0,
      left: 5,
    },
  },
  scales: {
    y: {
      display: false,
    },
    x: {
      display: false,
    },
  },
  elements: {
    line: {
      tension: 0,
      fill: false,
      borderCapStyle: 'round',
    },
    point: {
      radius: 0,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
};

export default options;