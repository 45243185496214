<template>
  <div class="aboutYouBodyGraphTabs">
    <div
      v-for="(periodType, index) in periodTypes"
      v-bind:key="index"
      v-bind:class="{
        'aboutYouBodyGraphTabs__tab': true,
        'aboutYouBodyGraphTabs__tab--active': selectedPeriodType === periodType.value,
      }"
      v-on:click="onPeriodTypeChange(periodType.value)"
    >
      {{ periodType.name }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
    },
  },

  data() {
    return {
      periodTypes: [
        { name: this.$t('body.graphFilterYearly'), value: 'year' },
        { name: this.$t('body.graphFilterMonthly'), value: 'month' },
      ],
      selectedPeriodType: this.modelValue,
    };
  },

  methods: {
    onPeriodTypeChange(type) {
      this.selectedPeriodType = type;
      this.$emit('update:modelValue', type);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.aboutYouBodyGraphTabs {
  display: flex;
  align-items: center;
}

.aboutYouBodyGraphTabs__tab {
  @include small;
  margin: 0 0.5rem 0 0;
  color: $color-grey;
  cursor: pointer;

  &--active {
    position: relative;
    color: $color-text;

    &:after {
      content: "";
      position: absolute;
      right: 0;
      bottom: -6px;
      left: 0;
      height: 2px;
      background: $color-text;
    }
  }

  &:last-child {
    margin: 0;
  }
}
</style>